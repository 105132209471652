var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('terms-dialog', _vm._b({
    on: {
      "isLoaded": _vm.setTermsList,
      "input": function (value) {
        return _vm.$emit('input', value);
      },
      "isValid": function (isValid) {
        return _vm.$emit('isValid', isValid);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          isAllAgreed = _ref.isAllAgreed;
        return [_c('div', _vm._g({}, on), [_c('div', {
          staticClass: "pb-12px"
        }, [_c('v-checkbox', {
          staticClass: "v-size--large",
          attrs: {
            "color": "primary",
            "label": "약관에 전체 동의합니다.",
            "value": isAllAgreed,
            "hide-details": "",
            "readonly": ""
          }
        })], 1), _c('v-card', {
          attrs: {
            "outlined": "",
            "rounded": "md"
          }
        }, [_c('div', {
          staticClass: "pa-16px pa-md-20px"
        }, _vm._l(_vm.termsList, function (terms, index) {
          return _c('v-checkbox', {
            key: terms._id,
            class: {
              'mt-8px mt-md-12px': index != 0
            },
            attrs: {
              "value": _vm.selected.includes(terms._id),
              "hide-details": "",
              "color": "primary",
              "readonly": ""
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function () {
                return [_vm._v(" " + _vm._s(terms.subject) + "에 동의 "), _c('span', {
                  staticClass: "pl-4px",
                  class: [terms.necessary ? 'red--text' : 'grey--text text--lighten-1']
                }, [_vm._v("(" + _vm._s(terms.necessary ? "필수" : "선택") + ")")])];
              },
              proxy: true
            }], null, true)
          });
        }), 1)])], 1)];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'terms-dialog', {
    params: _vm.params
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }