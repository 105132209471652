<template>
    <u-dialog v-model="shows" title="이용약관" persistent width="600">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, isValid, isAllAgreed }" />
        </template>
        <div v-if="isLoaded">
            <tit-wrap-small>
                <v-checkbox v-model="isAllAgreed" label="약관에 전체 동의합니다." readonly hide-details class="v-size--large" @click="toggleSelectedAll" />
            </tit-wrap-small>
            <v-divider />

            <page-section v-for="term in termsList" :key="term._id" class="page-section--xs pb-0">
                <template #containerImmersive>
                    <tit-wrap-small>
                        <template #titleContents>
                            {{ term.subject }}에 동의<span class="pl-4px" :class="[term.necessary ? 'red--text' : 'grey--text text--lighten-1']">({{ term.necessary ? "필수" : "선택" }})</span>
                        </template>
                        <v-checkbox v-model="selected" :value="term._id" label="약관동의" hide-details />
                    </tit-wrap-small>
                    <v-card outlined rounded="md">
                        <div class="pa-16px pa-md-20px mh-120px" style="overflow-y: auto;"><div v-html="term.content.replace(/\n/gi, '<br/>')" /></div>
                    </v-card>
                </template>
            </page-section>

            <div class="btn-wrap">
                <v-row class="row--sm">
                    <v-col>
                        <v-btn block outlined color="grey" class="v-size--xx-large" @click="cancel">취소</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn block color="primary" class="v-size--xx-large" @click="agree"> 동의 </v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";

export default {
    components: {
        PageSection,
        UDialog,
        TitWrapSmall,
        VPreviewInput,
    },
    props: {
        value: { type: Array, default: () => [] },
        params: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        isLoaded: false,
        selected: [],
        termsList: [],

        attrs_input,
    }),
    computed: {
        isValid() {
            return !this.termsList.filter(({ necessary }) => necessary).some(({ _id }) => !this.selected.includes(_id));
        },
        isAllAgreed() {
            return !this.termsList.some(({ _id }) => !this.selected.includes(_id));
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.selected = this.value;
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },

        isLoaded(isLoaded) {
            if (isLoaded) this.$emit("isLoaded", this.termsList);
        },
    },
    methods: {
        async init() {
            try {
                this.isLoaded = false;

                let { params } = this;
                var { termsList } = await api.v1.terms.gets({ params });
                this.termsList = termsList;

                this.isLoaded = true;
            } catch (error) {
                this.$handleError(error);
            }
        },

        toggleSelectedAll() {
            if (this.isAllAgreed) this.selected = [];
            else this.selected = this.termsList.map(({ _id }) => _id);
        },
        cancel() {
            this.selected = this.value;
            this.shows = false;
        },
        agree() {
            this.$emit("input", this.selected);
            this.$emit("isValid", this.isValid);
            this.shows = false;
        },
    },
};
</script>

<style></style>
